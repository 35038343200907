<template>
    <div class="chart-list-item-box">
        <p class="team-header">
            <span class="team-title">
                <span :title="data.name" class="team-name ellipsis">
                    <span>{{ data.name }}</span>
                </span>
                <SvgIcon v-if="data.trial && data.expireTime" name="team1" :color="valiateExpireTime(data.expireTime) ? '#666' : '#2b32c9'" :size="28" />
                <SvgIcon
                    v-else-if="!data.trial && data.expireTime"
                    :name="$store.getters.isEnglish ? 'trial-en' : 'trial'"
                    :color="valiateExpireTime(data.expireTime) ? '#666' : '#2b32c9'"
                    :size="30" />
            </span>
            <Space :size="10">
                <SvgIcon name="set-up" :tip="$t('config')" color="#2b32c9" :size="22" @click="$emit('showSetup', data)"></SvgIcon>
                <SvgIcon name="delete" :tip="$t('delete')" color="#2b32c9" :size="22" @click="$emit('deleteItem', data)"></SvgIcon>
            </Space>
        </p>
        <LineChartItem :data="{ title: $t('projectNum'), num: data.usedCount, total: data.maxCount }" />
        <LineChartItem :data="{ title: $t('memberNum'), num: data.memberCount }" />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { valiateExpireTime } from '@/utils/utils'
import LineChartItem from './LineChartItem.vue'
export default defineComponent({
    name: 'ChartListItem',
    components: {
        LineChartItem
    },
    props: {
        data: Object
    },
    setup() {
        return {
            valiateExpireTime
        }
    }
})
</script>

<style lang="less" scoped>
.chart-list-item-box {
    float: left;
    margin: 0.7%;
    padding: 20px 30px 6px;
    width: 23.4%;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(177, 177, 177, 0.25);
    .team-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;
        height: 24px;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #2b32c9;
        .team-name {
            margin-right: 10px;
            display: inline-block;
            height: 25px;
        }
        .team-title {
            display: flex;
            width: 70%;
            align-items: center;
        }
    }
    .line-item {
        margin-bottom: 18px;
        .line-text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            color: #333333;
            span {
                &:first-child {
                    font-size: 16px;
                    font-weight: 700;
                }
                &:last-child {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        .line-data {
            width: 100%;
            height: 14px;
            border-radius: 7px;
            background-color: #eaeaea;
            span {
                height: 100%;
                display: inline-block;
                background-color: #18d22e;
                border-radius: 7px;
                &.error {
                    background-color: #e62c35;
                }
            }
        }
    }
}
@media screen and (max-width: 1524px) {
    .chart-list-item-box {
        width: 31.5%;
    }
}
</style>
