<template>
    <Modal width="500px" v-model:visible="visible" @close="handleClose" :mask-closable="false" :unmount-on-close="true">
        <template #title>
            <p class="form-title">{{ t('user.userInfo') }}</p>
            <template v-if="data">
                <SvgIcon v-if="data.trial && data.expireTime" name="team1" :color="valiateExpireTime(data.expireTime) ? '#666' : '#2b32c9'" :size="28" />
                <SvgIcon
                    v-else-if="!data.trial && data.expireTime"
                    :name="$store.getters.isEnglish ? 'trial-en' : 'trial'"
                    :color="valiateExpireTime(data.expireTime) ? '#666' : '#2b32c9'"
                    :size="30" />
            </template>
        </template>
        <div>
            <Form ref="formDom" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                <FormItem :hide-label="true" row-class="form-item-require" field="from" v-if="operation">
                    <span class="form-item-title">{{ t('user.accountSource') }} </span>
                    <RadioGroup v-model.trim="formData.from">
                        <Radio value="email">{{ t('user.thisSystem') }}</Radio>
                        <Radio value="info">INFO</Radio>
                    </RadioGroup>
                </FormItem>
                <p class="form-item-view" v-if="!operation">
                    <span>{{ t('user.teamName') }}</span>
                    <span>{{ formData.username || '-' }}</span>
                </p>
                <FormItem field="email" :validate-trigger="['blur']" v-if="operation" :label="t('user.email')">
                    <Input v-model.trim="formData.email" spellcheck="false" :placeholder="t('holder.enterHolder')" :maxlength="50" />
                </FormItem>
                <p class="form-item-view" v-if="!operation">
                    <span>{{ t('user.email') }}</span>
                    <span class="ellipsis" :title="formData.email">{{ formData.email }}</span>
                </p>
                <p class="form-item-view" v-if="!operation">
                    <span>{{ t('user.addTime') }}</span>
                    <span>{{ moment(formData.createTime).format('YYYY-MM-DD HH:mm') || '-' }}</span>
                </p>
                <p class="form-title" style="margin-bottom: 10px">
                    {{ t('user.teamInfo') }}
                </p>
                <FormItem :hide-label="true" row-class="form-item-require" field="projectPlatforms">
                    <span class="form-item-title">{{ t('user.platform') }} </span>
                    <CheckboxGroup v-model="formData.projectPlatforms">
                        <Checkbox value="nuxiums">Nuxiums</Checkbox>
                        <Checkbox value="runthos">RunthOS</Checkbox>
                    </CheckboxGroup>
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <!-- <Button class="btn-fill" type="primary" html-type="submit" v-if="!operation">
                {{ t('format') }}
            </Button> -->
            <Button class="btn-fill" type="primary" html-type="submit" @click="handleSubmit" :loading="loading">
                {{ t('ok') }}
            </Button>
        </template>
    </Modal>
</template>

<script>
import { defineComponent, ref, watch, reactive, inject } from 'vue'
import { Form } from '@arco-design/web-vue'
import { valiateExpireTime } from '@/utils/utils'
import * as reg from '@/utils/formReg'
export default defineComponent({
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        operation: {
            type: Number,
            default: 1 // 1、添加；0、修改
        },
        data: {
            type: Object
        }
    },
    setup(props, ctx) {
        const t = inject('$t')
        const http = inject('$http')
        const formDom = ref(null || Form)
        const loading = ref(false)
        const msg = inject('$message')
        const moment = inject('$moment')
        const formRule = {
            username: [
                { required: true, message: t('tip.emptyTip', { key: t('user.name') }) },
                { required: true, match: reg.usernameReg, message: t('tip.nameRegTip') }
            ],
            email: [
                { required: true, message: t('tip.emptyTip', { key: t('user.email') }) },
                { match: reg.emailReg, message: t('tip.emailRegTip1') },
                { maxLength: 50, message: t('tip.emailRegTip') },
                {
                    validator: (val, callback) => {
                        const paxReg = /paxsz\.com$/
                        if (formData.from === 'info' && !paxReg.test(val)) {
                            callback(t('tip.emailRegTip2'))
                        }
                        callback()
                    }
                }
            ],
            projectPlatforms: [{ required: true, message: t('tip.emptyTip', { key: t('user.platform') }) }]
        }
        const visible = ref(props.showModal)
        watch(
            () => props.showModal,
            (val) => {
                if (props.operation) {
                    formData.email = ''
                    formData.projectPlatforms = []
                } else {
                    formData.projectPlatforms = props.data.projectPlatforms ? props.data.projectPlatforms.split(',') : []
                    formData.username = props.data.name
                    formData.email = props.data.email
                    formData.createTime = props.data.createTime
                }
                visible.value = val
            }
        )
        const formData = reactive({
            from: 'email',
            username: '',
            email: '',
            createTime: '',
            projectPlatforms: []
        })
        const handleClose = () => {
            loading.value = false
            formDom.value.resetFields()
            ctx.emit('closeModal')
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    loading.value = true
                    const params = {}
                    params.projectPlatforms = formData.projectPlatforms.join(',')
                    let api = null
                    if (props.operation) {
                        params.from = formData.from
                        params.email = formData.email
                        api = http.account.addUser(params)
                    } else {
                        params.teamId = props.data.id
                        api = http.account.editTeamInfoById(params)
                    }

                    api.then(({ data }) => {
                        loading.value = false
                        if (props.operation) {
                            msg.info(data)
                            ctx.emit('closeModal')
                        } else {
                            msg.info(t('modifySucc'))
                            ctx.emit('closeModal', formData)
                        }
                    }).catch(() => {
                        loading.value = false
                    })
                }
            })
        }
        const validateField = (field) => {
            formDom.value.validateField(field)
        }

        return {
            visible,
            formData,
            formRule,
            formDom,
            loading,
            moment,
            handleSubmit,
            validateField,
            handleClose,
            valiateExpireTime,
            t
        }
    }
})
</script>

<style lang="less" scoped>
.form-item-require {
    :deep(.arco-form-item-message) {
        width: 100%;
        text-align: right;
    }
}
.form-title {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
    padding-right: 10px;
}
.form-item-title {
    font-size: 14px;
    color: #4e5969;
    width: 200px;
}
.arco-form-item-label-col {
    display: none;
}
.form-item-view {
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    span:first-child {
        // width: 40%;
        color: #4e5969;
        // font-weight: bold;
    }
    span:last-child {
        text-align: right;
        width: 300px;
    }
}
</style>
