<template>
    <div ref="home" class="home">
        <div class="header-bar">
            <Button class="btn-fill" type="primary" size="large" @click="addUser">
                {{ $t('user.addTeam') }}
            </Button>
        </div>
        <template v-for="item in teamData" :key="item.id">
            <ChartListItem :data="item" @showSetup="setupHandler" @deleteItem="deleteFunc(item)" />
        </template>
        <p class="loading-tip">
            <Spin dot :size="6" v-if="teamData.length < count" />
            <span class="no-more" v-else>{{ $t('noMore') }}</span>
            <span class="total">{{ $t('total', { count }) }}</span>
        </p>
        <AddUserModal :operation="operation" :data="teamItem" :showModal="showUserModal" @closeModal="closeModal" />
    </div>
</template>

<script>
import { defineComponent, ref, inject, onMounted, onUnmounted } from 'vue'
import ChartListItem from '@/components/ChartListItem.vue'
import AddUserModal from '@/components/AddUserModal.vue'
import { Role } from '@/store'
export default defineComponent({
    name: 'Home',
    components: {
        ChartListItem,
        AddUserModal
    },
    setup(props, ctx) {
        const http = inject('$http')
        const showUserModal = ref(false)
        const operation = ref(1)
        const home = ref(null || HTMLElement)
        const teamData = ref([])
        const teamItem = ref(null)
        const modal = inject('$modal')
        const t = inject('$t')
        const msg = inject('$message')
        const setupHandler = (data) => {
            operation.value = 0
            teamItem.value = data
            showUserModal.value = true
        }
        const count = ref(1)
        const params = {
            page: 0,
            size: 24
        }

        const addUser = () => {
            showUserModal.value = true
            operation.value = 1
            teamItem.value = null
        }

        const getData = () => {
            http.account.getTeams(params).then(({ data }) => {
                teamData.value = teamData.value.concat(data.data)
                count.value = data.total
            })
        }
        const closeModal = (data) => {
            if (data) {
                teamItem.value.projectPlatforms = data.projectPlatforms.join(',')
            } else {
                params.page = 0
                params.size = 24
                teamData.value = []
                getData()
            }
            showUserModal.value = false
        }
        let containDom = null
        const scrollHandler = (e) => {
            if (e.target.scrollHeight === e.target.offsetHeight + e.target.scrollTop) {
                if (teamData.value.length === count.value) return
                params.page++
                getData()
            }
        }

        const deleteFunc = (item) => {
            modal.warning({
                hideCancel: false,
                maskClosable: false,
                title: t('prompt'),
                okText: t('ok'),
                cancelText: t('cancel'),
                content: t('tip.deleteTeamTip', { name: item.name }),
                onOk: () => {
                    http.account.deleteTeam(item.id).then(() => {
                        params.page = 0
                        params.size = 24
                        teamData.value = []
                        getData()
                        msg.info(t('delSucc'))
                    })
                }
            })
        }

        onMounted(() => {
            getData()
            containDom = document.querySelector('.main-contain')
            containDom && containDom.addEventListener('scroll', scrollHandler)
        })
        onUnmounted(() => {
            containDom && containDom.removeEventListener('scroll', scrollHandler)
        })

        return { Role, operation, teamItem, count, home, teamData, showUserModal, setupHandler, closeModal, deleteFunc, addUser }
    }
})
</script>

<style lang="less" scoped>
.home {
    padding: 30px 110px 50px;
    .header-bar {
        text-align: right;
        padding-right: 26px;
        padding-bottom: 10px;
    }
    .loading-tip {
        display: block;
        clear: both;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #999;
        margin-right: 8px;
        .total {
            float: right;
            color: #1d2129;
            padding-right: 16px;
        }
        .no-more {
            margin-right: -60px;
        }
    }
}
</style>
